import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import BlogSideBar from "../components/blog/blogsidebar.component"
import Img from "gatsby-image"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      tags
      title
      publishDate(formatString: "DD MMMM, YYYY")
      blogImage {
        fluid(maxWidth: 900) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      body {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      category {
        name
      }
    }
  }
`

const BlogDetail = ({ data, pageContext }) => {
  useEffect(() => {
    deckDeckGoHighlightElement()
  }, [])

  const post = data.contentfulBlogPost
  const { prev, next, categories, tags } = pageContext

  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.body.childMarkdownRemark.excerpt}
      />
      <section className="inner-intro bg-img-single-service light-color overlay-before parallax-background">
        <div className="container">
          <div className="row title">
            <div className="title_row">
              <h1 data-title="Blog Detail">
                <span>Blog Detail</span>
              </h1>
              <div className="page-breadcrumb">
                <Link to="/">Home</Link> / <span>Blog Detail</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Blog Post Section */}
      <section className="padding pt-xs-40">
        <div className="container">
          <div className="row">
            {/* Post Bar */}
            <div className="col-lg-9 blog-post-hr post-section">
              <div className="blog-post mb-30">
                <div className="post-meta">
                  <div className="post-more-link pull-right">
                    <div className="icons-hover-black">
                      <a href="#" className="facebook-icon">
                        <i className="fa fa-facebook" />{" "}
                      </a>
                      <a href="#" className="twitter-icon">
                        <i className="fa fa-twitter" />{" "}
                      </a>
                      <a href="#" className="googleplus-icon">
                        <i className="fa fa-google-plus" />{" "}
                      </a>
                      <a href="#" className="linkedin-icon">
                        <i className="fa fa-linkedin" />
                      </a>
                    </div>
                    <a className="btn-text xs-hidden">
                      <i className="fa fa-share-alt" />
                    </a>
                  </div>
                </div>
                <div className="post-header">
                  <h2>{post.title}</h2>
                </div>
                <div className="post-media">
                  <Img fluid={post.blogImage.fluid} alt={post.title} />
                  <span className="event-calender blog-date">
                    {post.publishDate}
                  </span>
                </div>
                <div className="post-entry">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.body.childMarkdownRemark.html,
                    }}
                  ></div>
                </div>
                {/* <div className="post-tag pull-left">
                  <i className="fa fa-tag" />
                  <span>
                    <a>awesome audio post</a>,
                  </span>
                  <span>
                    <a>quotes post</a>
                  </span>
                </div> */}
              </div>
              <hr />
              <div className="clearfix" />
              {/* Post Next Prev Bar */}
              <section className="mb-60">
                <div className="container">
                  <div className="item-nav">
                    {/* <a href="#!" className="item-prev">
                      <div className="prev-btn">
                        <i className="fa fa-angle-left" />
                      </div>
                      <div className="item-prev-text" />{" "}
                    </a>
                    <a href="#!" className="item-next">
                      <div className="next-btn">
                        <i className="fa fa-angle-right" />
                      </div>
                      <div className="item-next-text" />
                    </a> */}
                    {prev && (
                      <Link
                        to={`blog/${prev.node.slug}`}
                        title={prev.node.title}
                      >
                        <p>Previous</p>
                        <span>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth={0}
                            viewBox="0 0 24 24"
                            className="css-1hyj6ne"
                            style={{ verticalAlign: "middle" }}
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                          </svg>
                          {prev.node.title}
                        </span>
                      </Link>
                    )}
                    {next && (
                      <Link
                        to={`blog/${next.node.slug}`}
                        title={next.node.title}
                      >
                        <p>Next</p>
                        <span>
                          {next.node.title}
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth={0}
                            viewBox="0 0 24 24"
                            className="css-jmo9lw"
                            style={{ verticalAlign: "middle" }}
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
                          </svg>
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
              </section>
              {/* End Post Next Prev Bar */}
            </div>
            <BlogSideBar categories={categories} tags={tags} />
            {/* End Post Bar */}
          </div>
        </div>
      </section>
      {/* End Blog Post Section */}
    </Layout>
  )
}

export default BlogDetail
